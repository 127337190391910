.ProImg{
    max-height: 400px;
    border-bottom: 15px solid rgba(70, 70, 70, 0.562);
    border-right: 15px solid rgb(70, 70, 70);
    height: auto;
    width: auto;
}

.greeting{
    min-height: 380px;
    border-top: 15px solid rgb(70, 70, 70, 0.562);
    border-left: 15px solid rgb(70, 70, 70);
}

.navbar-toggler-icon{
    background-color: rgb(112, 112, 112)!important;
    border-radius: 2px;
}

.navbar-brand, .nav-link{
    color: rgb(190, 190, 190)!important;
}
#get-resume{
    padding: 5px 15px;
    font-size: 20px;
    text-decoration: none;
    border: 2px solid gray;
    color: rgb(155, 85, 85);
    box-shadow: 4px 5px 5px rgb(155, 85, 85);
}
#get-resume:hover{
    border: 2px solid gray;
    color: rgb(190, 190, 190);
    background:rgb(155, 85, 85) ;
    box-shadow: 4px 5px 5px rgb(190, 190, 190);
}
.social-icon{
    font-size: 30px;
    
}
.social-icon a{
    text-decoration: none;
    color: rgb(70, 70, 70);
    padding: 7px;
   
}
.icon{
    padding-top: 55px!important;
    
}

.icon:hover{
    color: rgb(126, 126, 161);
}

#dot{
    animation: change 1.5s infinite;
}
@keyframes change {
    0% { color: red }
    50%   { color: rgb(255, 255, 255) }
    100% { color: red }
}