.skills{
    list-style: none;
    background-color: rgb(20, 20, 20);
    width: 60vw;
    margin: 60px auto;
    padding: 20px;
    color: white;
    height: auto;
}

.skills h5{
    margin-bottom: 10px;
}
.skills li{
    margin: 20px 0;
}
.skills li img{
    padding: 5px;
    margin: 5px;
    background: rgb(37, 37, 37);
    border-radius: 50%;
    box-shadow: 0 0 10px rgb(0, 0, 0);
    transition: 0.2s ease;
}

.skills li img:hover{
    transform: translateY(-10px);
    box-shadow: 0 0 10px rgb(153, 153, 153);
}

.skills li img title{
    padding: 5px;
    margin: 5px;
}
.progress-line{
    height: 12px;
    width: 100%;
    position: relative;
    background: #1a1a1a;
    border-radius: 5px;
    box-shadow:0px 0px 10px rgb(67, 67, 68);
                     
}
.progress-line span{
    height: 100%;
    background: #353535;
    position: absolute;
    border-radius: 10px;
}

.progress-line.html span{
    width: 85%;
}
.progress-line.js span{
    width: 80%;
}
.progress-line.node span{
    width: 50%;
}
.progress-line.tools span{
    width: 60%;
}

.progress-line span::before{
    position: absolute;
    content: "";
    right: 0;
    top: -10px;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-bottom-width: 0px;
    border-right-width: 0px;
    border-top-color: rgb(78, 78, 78);
}

.progress-line span::after{
    position: absolute;
    right: 0;
    top: -35px;
    color:#fff;
    font-size: 15px;
    font-weight: 500;
    background: rgb(63, 63, 63);
    padding: 1px 8px;
    border-radius: 3px;
}

.progress-line.html span::after{
    content: "85%";
}
.progress-line.js span::after{
    content: "80%";
}
.progress-line.node span::after{
    content: "50%";
}
.progress-line.tools span::after{
    content: "60%";
}
/* .bar span{
    height: 8px;
    float: left;
    background: #0e2133;
}

.html{
    width: 80%;
    animation: html 2s;
}
.node{
    width: 50%;
    animation: node 2s;
}
.js{
    width: 70%;
    animation: js 2s;
}
.tools{
    width: 50%;
    animation: tools 2s;
}
@keyframes html{
    0%{
        width: 0%;
    }
    100%{
        width: 90%;
    }
}

@keyframes js{
    0%{
        width: 0%;
    }
    100%{
        width: 70%;
    }
}

@keyframes node{
    0%{
        width: 0%;
    }
    100%{
        width: 50%;
    }
}

@keyframes tools{
    0%{
        width: 0%;
    }
    100%{
        width: 50%;
    }
} */