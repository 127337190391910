
.top{
  margin-top: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: rgb(20, 20, 20);
}

.middle{
  background-color: black;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.blog-area{
  margin: 0;
  padding: 0;
  background-color: rgb(20, 20, 20);
}

.contact{
  background-color: black;
  margin: 0;
  padding: 0;
}
.footer{
  overflow: hidden;
  background-color: rgb(20, 20, 20);
}