

.contact-form form{
   
    box-sizing: border-box;
    text-align: center;
}

form input[name="firstName"], input[name="lastName"],input[name="email"] {
    line-height: 35px;
    padding: 10px;
    margin: 10px;
    border-radius: 30px;
    background-color: rgb(189, 189, 189);
    border:3px solid rgb(39, 39, 39);
    box-shadow: 0 0 15px rgb(83, 83, 83);
}
form textarea{
    line-height: 50px;
    padding: 10px;
    margin: 10px;
    border-radius: 30px;
    background-color: rgb(189, 189, 189);
    border:3px solid rgb(39, 39, 39);
    box-shadow: 0 0 15px rgb(83, 83, 83);
}
form input[type=submit]{
    padding: 5px 10px;
    margin: 10px;
    background-color: transparent;
    border: 2px solid rgb(129, 123, 123);
    border-radius: 15px;
    color: rgb(211, 211, 211);
}
form input[type=submit]:hover{
    background-color:rgb(189, 189, 189);
    border: 2px solid rgb(189, 189, 189);
    color: black;
}

form input[name="firstName"], input[name="lastName"]{
    width: 44%;
   
}
form input[name="email"], textarea{
    width: 91%;
}

form input:focus, textarea:focus{
    outline: none;
    box-shadow: 0px 0px 15px rgb(121, 154, 207);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(17, 17, 17);
    opacity: 1; /* Firefox */
  }