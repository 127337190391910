.card-img img{
    width: 100%;
    max-height: 260px;
    padding-top: 5px;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 0px;
    size: cover;
}
.tech{
    padding: 2px 5px;
    background-color: rgb(80, 80, 80);
    margin: 5px;
    float: left;
    border-radius: 3px;
    color: rgb(195, 195, 195);
    
}

.card{
    background-color: rgb(0, 0, 0)!important ;
    box-shadow: 0 2px 15px rgb(49, 49, 49);
    transition: transform 0.75s;
    
}
.card:hover{
    box-shadow: 0 2px 15px rgb(115, 119, 138);
    transform: scale(1.1);
    
}

.card-info{
    min-height: 220px;
}