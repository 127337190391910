

.about{
    background-color: rgb(0, 0, 0) !important;
    color: rgb(190, 190, 190);
    padding: 50px 50px;

}

.about-container{
    box-shadow: 0px 10px 15px rgb(31, 31, 31);
    background-color: rgb(0, 0, 0);
}

.about-container:hover{
    box-shadow: 0px 5px 12px rgb(93, 93, 93);

}